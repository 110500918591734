<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';
	import { onMount } from 'svelte';
	import Ratings from './rating_meter.svelte';
	import reviewsJSON from './reviews.json';
	import reviewsLogo from './reviewsio-logo.svg';

	let isJS = $state(false);

	// Ensure JavaScript is running
	onMount(() => {
		isJS = true;
	});

	let {
		title = 'Customer Reviews',
		backgroundColor = 'grey',
		reviews = reviewsJSON,
	} = $props();

	let container;
	let reviewsFiltered = reviews?.reviews;

	function next() {
		if (!container) {
			return;
		}
		setupScrollEndListener(container);

		if (container.scrollLeft >= container.scrollWidth - container.clientWidth) {
			// If at the end, scroll to the start
			container.scrollTo({
				left: 0,
				behavior: 'smooth',
			});
		} else {
			container.scrollBy({ left: container.clientWidth, behavior: 'smooth' });
		}
	}

	function prev() {
		if (!container) {
			return;
		}
		setupScrollEndListener(container);
		if (container.scrollLeft <= 0) {
			// If at the start, scroll to the end
			container.scrollBy({
				left: container.scrollWidth,
				behavior: 'smooth',
			});
		} else {
			container.scrollBy({
				left: -container.clientWidth,
				behavior: 'smooth',
			});
		}
	}

	// when using the svelte onscroll directive the event was not being removed properly,
	// so i'm just adding it within the use: directive instead
	function setupScrollListener(el) {
		el.addEventListener('scroll', scrollListener);
	}

	function scrollListener(event) {
		const el = event.target;
		collapseExpandedComments(el);
	}

	// For some reason, if you collapse during scrollBy() it breaks the smooth scroll,
	// so we need to listen for the end of the scroll, and then reattach the scroll event listener
	function setupScrollEndListener(el) {
		el.removeEventListener('scroll', scrollListener);
		el.addEventListener('scrollend', scrollEndListener, {
			once: true,
		});
	}

	function scrollEndListener(event) {
		const el = event.target;
		collapseExpandedComments(el);
		setupScrollListener(el);
	}

	function collapseExpandedComments(el) {
		const expandedComments = el.querySelectorAll(
			'input[type="checkbox"]:checked',
		);
		expandedComments.forEach((input) => {
			input.checked = false;
		});
	}
</script>

<Section {backgroundColor}>
	<h2>{title}</h2>
	<div class="relative !col-span-full mx-auto w-full max-w-3xl">
		{#if isJS}
			<button
				onclick={prev}
				aria-label="Previous"
				class="arrow left max-md:hidden"
			></button>
			<button onclick={next} aria-label="Next" class="arrow right max-md:hidden"
			></button>
		{/if}

		<div class="content-grid">
			<div
				class="bubble-arrow
		bg-indow-white text-indow-grey-dark relative mb-10
		w-full rounded-lg px-4 py-5"
			>
				<div
					class="flex w-full flex-row flex-wrap items-center justify-between gap-8"
				>
					<div class="grow">
						<div class="flex flex-row items-center gap-2">
							<div class="text-xl uppercase">Excellent</div>
							<Ratings
								stars="5"
								rating={reviews?.stats?.company?.average_rating}
							/>
						</div>
						<div class="mt-2">
							<span class="!text-indow-grey-dark">
								{reviews?.stats?.company?.average_rating}
								<span class="!text-indow-grey">Average</span>
							</span>
							<span class="!text-indow-grey-dark">
								{reviews?.stats?.company?.review_count}
								<span class="!text-indow-grey">Reviews</span>
							</span>
						</div>
					</div>
					<div class="grow text-end">
						<a
							href="https://www.reviews.io/company-reviews/store/indow1?utm_source=indow1&utm_medium=widget&utm_campaign=carousel"
							target="_blank"
							rel="noreferrer"
							class="inline-block w-44"
						>
							<Image src={reviewsLogo} alt="Reviews.io logo" loading="lazy" />
						</a>
					</div>
				</div>
			</div>
		</div>
		<div
			bind:this={container}
			use:setupScrollListener
			class="comments scrollbar-force-hide
		relative !col-span-full
		mx-auto flex w-full max-w-3xl
		snap-x snap-mandatory overflow-x-auto
		overflow-y-hidden overscroll-x-none scroll-smooth"
		>
			{#each reviewsFiltered as review, i (review.id)}
				<div class="relative w-full flex-none snap-start px-6 pb-8 md:w-1/2">
					<div class="flex items-center gap-4">
						<div class="text-lg font-medium text-nowrap">
							{review?.author?.name}
						</div>
						<Ratings rating={review?.rating} stars="5" />
					</div>

					<p class="text-left leading-5">
						{#if review?.comments.length > 200}
							<input type="checkbox" id={`check${i}`} />
							<span class="comments-wrapper">
								<span class="comments-wrapper-inside">{review?.comments}</span>
							</span>
							<label
								class=" text-indow-blue block cursor-pointer pt-2"
								for={`check${i}`}
							>
							</label>
						{:else}
							{review?.comments}
						{/if}
					</p>
					<div
						class="pointer-events-none absolute bottom-0 left-0 mt-3 w-full pr-8 pb-1 text-right text-xs"
					>
						Posted {review?.time_ago}
					</div>
				</div>
			{/each}
		</div>
	</div>
</Section>

<style lang="scss">
	.scrollbar-force-hide {
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* IE & Edge */
	}

	.scrollbar-force-hide::-webkit-scrollbar {
		display: none;
	}

	.bubble-arrow::after {
		content: '';

		position: absolute;
		bottom: -1rem;
		left: 0;
		transform: translateX(-50%);
		margin-left: 50%;
		width: 0;
		height: 0;
		border-left: 0.75rem solid transparent;
		border-right: 0.75rem solid transparent;
		border-top: 1rem solid white;
	}

	.comments {
		&-wrapper {
			display: grid;
			grid-template-rows: 0fr;
			transition: grid-template-rows 300ms ease-in;
			&-inside {
				line-height: 1.3rem;
				min-height: 4rem;
				overflow: hidden;
			}
		}
		input {
			display: none;
			&:checked + .comments-wrapper {
				grid-template-rows: 1fr;
			}

			&:checked ~ label::after {
				content: 'Show Less';
			}
			&:not(:checked) ~ label::after {
				content: 'Show More';
			}
		}
	}

	.arrow {
		position: absolute;
		background: none;
		box-sizing: content-box;
		border-left: none;
		border-top: none;
		border-right: 0.45rem solid var(--color-indow-grey-light);
		border-bottom: 0.45rem solid var(--color-indow-grey-light);
		width: 1rem;
		height: 1rem;
		margin: 0.5rem;
		cursor: pointer;
		transition: opacity 0.5s;
		place-self: start;
		margin-block-start: 7rem;
		top: 4rem;
		&:hover {
			border-color: var(--color-indow-grey-light);
		}

		&.left {
			transform: rotate(135deg);
			-webkit-transform: rotate(135deg);
			left: -2rem;
		}

		&.right {
			transform: rotate(-45deg) translateY(-50%);
			-webkit-transform: rotate(-45deg);

			right: -2rem;
		}
	}
</style>
