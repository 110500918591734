<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';

	import inner from './star-inner.svg';
	import outer from './star.svg';

	let { stars = 5, rating = 0.0, starWidth = '1rem' } = $props();

	let starLength = { length: Math.floor(stars) };
	let remainder = rating % 1;
	let percentage = remainder * 100;
</script>

<div class="container flex min-h-[1rem] flex-row gap-1 text-transparent">
	<!-- eslint-disable-next-line -->
	{#each starLength as star, i}
		<div class="star grid" style={`width: ${starWidth}`}>
			<div class="col-span-full row-span-full outline">
				<Image src={outer} width="158" height="150" />
			</div>
			<div
				class="col-span-full row-span-full"
				style={`clip-path: polygon(0 0, 0 100%,  ${
					i + 1 <= rating ? 100 : i + 1 == Math.ceil(rating) ? percentage : 0
				}% 100%,  ${
					i + 1 <= rating ? 100 : i + 1 == Math.ceil(rating) ? percentage : 0
				}% 0%);`}
			>
				<Image src={inner} width="158" height="150" />
			</div>
		</div>
	{/each}
</div>
